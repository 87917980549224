
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import CustomerEntity from "../entity";
import CustomerOverviewShowLocations from "./show/locations";
import CustomerOverviewShowContacts from "./show/contacts";
import CustomerOverviewShowTickets from "./show/tickets";

export default class CustomerOverviewShow extends DefaultController {
    async init() {
        this.entity = "customers";
        await super.init();
        this.childs = {
            locations: new CustomerOverviewShowLocations(this),
            contacts: new CustomerOverviewShowContacts(this),
            tickets: new CustomerOverviewShowTickets(this)
        }
    }

    protected getEntityData(elem: any) {
        return CustomerEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#editCustomerForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editCustomerForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.getEntity()
                    const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editCustomer") as HTMLElement))
                    if (bsElem) {
                        bsElem.hide();
                    }
                    this.toastr.success(`${Utils.translate('customer.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        });
    }
}